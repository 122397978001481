const E = {
  title: {
    receiving: "Receiving",
  },
  actions: {
    clearForm: "Clear Form",
    submit: "Submit",
    update: "Update Record",
    submitAndClear: "Submit & Clear",
    backToTop: "Back to Top",
    cancel: "Cancel",
    cocAndOCRData: "COC & OCR Data",
    backtoQueue: "Back To Queue",
    backtoInspection: "Back To Inspection",
    massUpdate: "Mass Update",
    deleteSelected: "Delete Selected",
  },
  form: {
    generalInfo: "General Info",
    loadCOC: "Load COC",
    cocFiles: "COC Files",
    loadOCRData: "Load OCR Data",
    numberOfCoolers: "# of Coolers",
    other: "Other",
    omitCoolerTemps: "Omit Cooler Temps",
    receiptNumber: "Receipt #",
    receiptNumberLength: "Receipt # must be at most 20 characters",
    cocNumber: "COC #",
    prelogNumber: "Prelog #",
    eCOCNumber: "eCOC #",
    invalidECOCNumber: "This is not a valid eCOC number.",
    invalidPrelogNumber: "This is not a valid Prelog number.",
    changeCOCModal:
      "You are changing the COC # to a different eCOC #. Are you sure you want to continue?",
    changePrelogModal:
      "You are changing the COC # to a different Prelog #. Are you sure you want to continue?",
    changeClientCOCModal:
      "The selected Client # does not match the eCOC #. Please verify.",
    changeClientPrelogModal:
      "The selected Client # does not match the Prelog #.  Please verify.",
    eCOCNotSubmittedModal:
      "This eCOC has not been submitted in the Customer Portal yet.  Your receipt record will still be saved and Receiving details will be updated once it has been submitted.",
    client: "Client",
    timingAndTransport: "Timing & Transport",
    receivedDate: "Received Date",
    receivedDateMax: "Date should be less than or equal to the current date",
    receivedTime: "Received Time",
    receivedTimeMax: "Time should be less than or equal to the current time",
    earliestExpiryDate: "Earliest Expiry Date",
    earliestExpiryTime: "Earliest Expiry Time",
    carrier: "Carrier",
    waybill: "Waybill",
    hold: "Hold",
    subcontract: "Subcontract",
    preApproved: "Pre-Approved",
    comments: "Comments",
    longestTAT: "Longest TAT",
    requestedTAT: "Requested TAT",
    priority: "Priority",
    prefix: "Prefix",
    sampleType: "Sample Type",
    sealPresent: "Seal Present",
    sealIntact: "Seal Intact",
    coolingMediaPresent: "Cooling Media Present",
    updateSuccess: "Your record has been updated",
    invalidDateFormat: "Invalid date format",
    invalidTimeFormat: "Invalid time format",
  },
  cooler: {
    coolers: "Coolers",
    selectAll: "Select All",
    temperature: "Temperatures",
    temperature1: "Temp #1",
    temperature2: "Temp #2",
    temperature3: "Temp #3",
    average: "Average",
    sealPresent: "Seal Present",
    sealIntact: "Seal Intact",
    coolingMedia: "Cooling Media",
    coolingMediaPresent: "Cooling Media Present",
    delete: "Delete",
    add: "Add a Cooler",
    yes: "Yes",
    no: "No",
    updateMultipleCoolers: "Update Multiple Coolers",
    deleteMultipleCoolers: "Delete Multiple Coolers",
    deleteMultipleMessage: `You have selected {{numberOfCoolers}} coolers to delete. Are you sure you want to delete?`,
    deleteMultipleMessage_one: `You have selected 1 cooler to delete. Are you sure you want to delete?`,
  },
  confirm: {
    confirmSubmission: "Confirm submission?",
    confirmUpdate: "Confirm update?",
    confirmLeave:
      "Your changes will not be saved. Are you sure you want to continue?",
    prepaidClient:
      "This client uses the prepaid workflow in the Customer Portal. Your receipt record will still be saved, but you won’t be able to inspect or create a job. When payment is received, import the associated eCOC in LIMS to access the Inspection – Prepaid Job Review screen and clear this record from the queue.",
  },
  duplicateFile: {
    title: "This file has already been attached. Please select a new file.",
  },
};

const F = {
  title: {
    receiving: "Réception",
  },
  actions: {
    clearForm: "Effacer le formulaire",
    submit: "Soumettre",
    update: "Mise à jour",
    submitAndClear: "Soumettre et effacer",
    backToTop: "Haut de page",
    cocAndOCRData: "Données CDR et ROC",
    backtoQueue: "Réception d'échantillons",
    backtoInspection: "L'inspection",
    massUpdate: "Mise à jour en bloc",
    deleteSelected: "Supprimer la sélection",
  },
  form: {
    generalInfo: "Informations générales",
    loadCOC: "Charger les données CDR et OCR",
    cocFiles: "Fichiers CDR",
    loadOCRData: "Charger les données OCR",
    numberOfCoolers: "Nbr. de glacières",
    other: "Autre",
    omitCoolerTemps: "Omettre les températures",
    receiptNumber: "No. de réception",
    receiptNumberLength: "No de Réception ne peut excéder 20 caractères",
    cocNumber: "No. de CDR ",
    prelogNumber: "No. de Prelog",
    eCOCNumber: "No. d'eCDR",
    invalidECOCNumber: "Ce numéro d’eCDR n'est pas valide.",
    invalidPrelogNumber: "Ce numéro de Prelog n'est pas valide.",
    changeCOCModal:
      "Vous changez le numéro de CDR pour un autre numéro d'eCDR. Êtes-vous sûr de vouloir continuer ?",
    changePrelogModal:
      "Vous changez le numéro de CDR pour un autre numéro de Prelog. Êtes-vous sûr de vouloir continuer ?",
    changeClientCOCModal:
      "Ce numéro de client ne correspond pas au numéro de d’eCDR. Veuillez verifier",
    changeClientPrelogModal:
      "Ce numéro de client ne correspond pas au numéro de Prelog. Veuillez verifier.",
    eCOCNotSubmittedModal:
      "Cette eCDR n'a pas encore été soumise dans le portail client.  Vos données seront sauvegardées et les détails de réception seront mis à jour une fois que la eCDR aura été soumise.",
    client: "Client",
    timingAndTransport: "Horaires et transports",
    receivedDate: "Date de réception",
    receivedDateMax: "La date doit être inférieure ou égale à la date courante",
    receivedTime: "Heure de réception",
    receivedTimeMax:
      "L'heure doit être inférieure ou égale à l'heure courante.",
    earliestExpiryDate: "Date d'expiration la plus proche",
    earliestExpiryTime: "Heure d'expiration au plus tôt",
    carrier: "Transporteur",
    waybill: "No de bordereau d’expédition",
    hold: "Hold",
    subcontract: "Sous-traitance",
    preApproved: "Pré-approuvé",
    comments: "Commentaires",
    longestTAT: "Délai le plus long",
    requestedTAT: "Délai demandé",
    priority: "Priorité",
    prefix: "Préfixe",
    sampleType: "Type d'échantillon",
    sealPresent: "Sceau présent",
    sealIntact: "Sceau intact",
    coolingMediaPresent: "Média de refroidissement présent",
    updateSuccess: "Votre dossier a été mis à jour",
    invalidDateFormat: "Format de date invalide",
    invalidTimeFormat: "Format d'heure invalide",
  },
  cooler: {
    coolers: "Glacières",
    selectAll: "Sélectionner tout",
    temperature: "Températures",
    temperature1: "Temp No. 1",
    temperature2: "Temp No. 2",
    temperature3: "Temp No. 3",
    average: "Moyen",
    sealPresent: "Sceau présent",
    sealIntact: "Sceau intact",
    coolingMedia: "Média de refroidissement",
    coolingMediaPresent: "Média de refroidissement présent",
    delete: "Effacer",
    add: "Ajouter une glacière",
    yes: "Oui",
    no: "Non",
    updateMultipleCoolers: "Mise à jour de multiples glacières",
    deleteMultipleCoolers: "Supprimer multiples glacières",
    deleteMultipleMessage:
      "Vous allez supprimer {{numberOfCoolers}} glacières. Êtes-vous sûr de vouloir les supprimer ?",
    deleteMultipleMessage_one:
      "Vous allez supprimer 1 glacière. Êtes-vous sûr de vouloir les supprimer ?",
  },
  confirm: {
    confirmSubmission: "Confirmer la soumission?",
    confirmUpdate: "Confirmer la mise à jour?",
    confirmLeave:
      "Vos modifications ne seront pas enregistrées. Êtes-vous sûr de vouloir continuer ?",
    prepaidClient:
      "Ce client utilise le workflow prépayé dans le portail client. Vos données de réception seront enregistrées, mais vous ne pourriez pas inspecter ni créer un projet en LIMS. Lorsque le paiement est reçu, importez l'eCDR associé dans LIMS pour accéder à l'écran Inspection – Prepaid Job Review et effacez cette entrée de la liste de priorisation de réception des échantillons.",
  },
  duplicateFile: {
    title:
      "Ce fichier a déjà été joint. Veuillez sélectionner un nouveau fichier.",
  },
};

const resources = { E, F };

export default resources;
